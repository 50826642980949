import { Controller } from "stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  // update() {
  //   Rails.fire(this.element, 'submit');
  // }

  static targets = ["alert", "alertMessage", "alertXbtn"];

  connect() {
    // console.log("in workshop controller");
    // console.log(this.alertMessageTarget);
    this.registerAlertClasses();
  }

  closeAlert() {
    leave(this.alertTarget);
  }

  openAlert() {
    enter(this.alertTarget);
    // console.log(this.alertTarget);
    setTimeout(() => this.closeAlert(), 10 * 1000);
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    // console.log(data.message);
    this.styleAlertContainer(data.message);
    this.alertMessageTarget.textContent = data.message;
    this.openAlert();
  }

  styleAlertContainer(message) {
    if (message.includes("incoming")) {
      this.setAlertSuccessStyles();
    } else {
      this.setAlertErrorStyles();
    }
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    this.setAlertErrorStyles();
    this.alertMessageTarget.textContent = data.message;
    enter(this.alertTarget);
    setTimeout(() => this.closeAlert(), 5 * 1000);
  }

  registerAlertClasses() {
    this.alertSuccessClasses =
      this.alertTarget.dataset.alertSuccessClasses.split(" ");
    this.alertSuccessXbtnClasses =
      this.alertXbtnTarget.dataset.alertSuccessXbtnClasses.split(" ");
    this.alertErrorClasses =
      this.alertTarget.dataset.alertErrorClasses.split(" ");
    this.alertErrorXbtnClasses =
      this.alertXbtnTarget.dataset.alertErrorXbtnClasses.split(" ");
  }

  setAlertSuccessStyles() {
    this.alertTarget.classList.remove(...this.alertErrorClasses);
    this.alertTarget.classList.add(...this.alertSuccessClasses);
    this.alertXbtnTarget.classList.remove(...this.alertErrorXbtnClasses);
    this.alertXbtnTarget.classList.add(...this.alertSuccessXbtnClasses);
  }

  setAlertErrorStyles() {
    this.alertTarget.classList.remove(...this.alertSuccessClasses);
    this.alertTarget.classList.add(...this.alertErrorClasses);
    this.alertXbtnTarget.classList.remove(...this.alertSuccessXbtnClasses);
    this.alertXbtnTarget.classList.add(...this.alertErrorXbtnClasses);
  }
}
